.Container {
  display: flex;
  width: 100%;
  height: 136px;
  margin: 0 auto;
  justify-content: space-evenly;
  flex-basis: 30%;
}

.Product {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 30%;
  margin-top: 16px;
  cursor: pointer;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 12px;
  color: rgb(95, 95, 95);
  border-bottom: 4px solid transparent;
  font-family: "Montserrat";
  text-align: center;
  transition: all 0.4s ease;
}

.Product:hover {
  color: tomato;
  border-color: tomato;
}

.ProductText {
  font-weight: 400;
  width: 80%;
}

.active {
  color: tomato;
  border-color: tomato;
}

.ProductImage {
  width: 100px;
  height: 62px;
  margin-bottom: 8px;
}
