.sectionContainer {
  width: 80%;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  font-family: "Montserrat";
  overflow-x: hidden;
}
.WMUDImg {
  max-width: 240px;
  margin-bottom: 4%;
  margin-top: 5%;
  transition: width 0.4s;
}

.ImgWrapper {
  overflow-x: hidden;
}
.WMUDCategory {
  flex-basis: 33%;
  display: flex;
  font-size: 16px;
  line-height: 120%;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 2%;
  margin-bottom: 5%;
  overflow-y: hidden;
  overflow-x: hidden;
}
.WMUDCategory ul {
  list-style: none;
  text-align: center;
  padding: 0;
}
.WMUDCategory ul li {
  padding: 2% 0;
}
.WMUDCategory h3 {
  margin: 20px auto;
  font-size: 20px;
  padding: 0 8%;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: normal;
}

.itemTitle {
  font-family: "Montserrat";
}

.downloadLink {
  display: block;
  width: 140px;
  margin: 0 auto;
  text-decoration: none;
  color: black;
  font-family: "Lato";
  padding: 10px 40px;
  font-size: 14px;
  border: 2px solid black;
  transition: background-color 0.5s ease;
}
.downloadLink:hover {
  background-color: gainsboro;
}

.divider {
  height: 2px;
  width: 80%;
  color: black;
}
@media (min-width: 1024px) {
  .WMUDCategory h3 {
    padding: 0;
    padding-bottom: 8%;
    width: 80%;
    height: 48px;
    font-size: 20px;
    font-weight: normal;
  }
  .WMUDCategory {
    height: 700px;
  }

  .sectionContainer {
    flex-flow: row;
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .WMUDImg {
    max-width: 250px;
  }

  .WMUDCategory:nth-child(3) .WMUDImg {
    width: 350px;
  }
}
@media (min-width: 1280px) {
  .WMUDImg {
    max-width: 320px;
    margin-top: 48px;
  }
  .WMUDCategory:nth-child(2) .WMUDImg {
    width: 450px;
  }
}
