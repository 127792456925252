.heroBanner {
  height: 70vh;
  max-height: 952px;
  position: relative;
  bottom: 0;
  left: 0;
}

.video {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.LogoBanner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80vw;
  max-width: 400px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%);
}

@media (min-width: 620px) {
  .heroBanner {
    height: 80vh;
  }
}

@media (orientation: landscape) {
  .heroBanner {
    background-size: contain;
  }
}

@media (min-width: 1024px) {
  .LogoBanner {
    width: 50vh;
    min-width: 400px;
    padding: 10px;
  }
}
