.Container {
  width: 80%;
  margin: 100px auto 10vh auto;
}
.header {
  font-family: "Lato";
}

.paragraph {
  font-size: 16px;
  font-family: "Montserrat";
}
