.sectionContainer {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  line-height: 140%;
}

@media (min-width: 1024px) {
  .sectionContainer {
    flex-flow: row;
  }
}
@media (min-width: 1024px) {
  .sectionContainer {
    align-items: flex-end;
  }
  .sectionContainer > * {
    width: 25%;
  }
}
