.SiteNav {
  flex-basis: 33%;
  display: block;
}
.SectionTitle {
  font-size: 18px;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
}
.SectionTitle::before,
.SectionTitle::after {
  background-color: #868585;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 100px;
}
.SectionTitle::before {
  right: 0.5em;
  margin-left: -50%;
}
.SectionTitle::after {
  left: 0.5em;
  margin-right: -50%;
}
.footerNav {
  padding: 0;
  width: 80%;
  margin: 20px auto;
  list-style: none;
  text-decoration: none;
  text-align: center;
}

.footerNav li {
  margin-top: 12px;
}
.footerNav li:hover {
  color: tomato;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .SiteNav {
    display: block;
  }
}
