.MobNavContainer {
  width: 100%;
  overflow-y: auto;
  position: absolute;
  top: 16px;
  left: 0;
  font-family: "Lato";
}

.MainList {
  list-style: none;
  padding-left: 0px;
}

.secondaryList {
  margin-left: 40px;
  font-size: 18px;
  margin-top: 12px;
  line-height: normal;
}
.secondaryList a {
  margin-bottom: 16px;
}
.secondaryList .active {
  color: tomato;
}
.Icon {
  margin-right: 20px;
}
.SmallIcon {
  margin-right: 10px;
}
a {
  text-decoration: none;
  color: inherit;
}

.listItem {
  display: flex;
}
.listIcon {
  display: block;
  font-size: 16px;
  margin-right: 8px;
}

.MobNavContainer a {
  display: block;
  color: inherit;
  width: 90%;
  box-sizing: border-box;
  text-decoration: none;
}
.linkActive {
  padding: 3px 20px 0 40px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: white;
  background-color: tomato;
  text-decoration: none;
  margin-left: 0;
  transition: all 0.4s ease;
}

.accordionSummary > div {
  margin: 0 !important;
}

.InactiveLink {
  padding-left: 20px;
  line-height: 40px;
}

.root {
  margin: 0;
  padding: 0;
  box-shadow: none;
}

.PrimaryItem {
  min-height: 40px;
  font-size: 18px;
  position: relative;
  line-height: 40px;
}

.active {
  color: tomato;
}
