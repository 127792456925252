.ProductsImg {
  max-width: 90%;
  margin-bottom: 4%;
  border-radius: 20px;
}
.ProductsCategory {
  display: flex;
  font-size: 16px;
  line-height: 120%;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
}

.SeeMoreBtn {
  width: 140px;
  height: 40px;
  line-height: 40px;
  border-radius: 360px;
  border: none;
  margin: 0 auto;
  background-color: #ffbf46;
  font-family: "Lato";
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  color: white;
  outline: none;
  cursor: pointer;
  transition: background-color 0.4s ease;
}
.SeeMoreBtn:hover,
.SeeMoreBtn:active {
  background-color: #f8ad21;
}
.ProductsTitle {
  margin: 0;
  font-size: 20px;
  padding: 8% 8%;
  font-family: "Montserrat";
  font-weight: 400;
}

@media (min-width: 1024px) {
  .ProductsCategory h3 {
    font-size: 20px;
  }
}
