.FooterContainer {
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: #dfe3e5;
  position: relative;
  font-family: "Lato";
  color: #5f5e5e;
  text-align: center;
  padding: 2% 0;
}

.FooterCopyright {
  background-color: #dfe3e5;
  font-family: "Lato";
  text-align: center;
  padding: 20px 0;
  color: #5f5e5e;
  font-size: 14px;
}
.FooterCopyright:hover {
  color: tomato;
}
@media (min-width: 1024px) {
  .FooterContainer {
    flex-flow: row;
  }
}
