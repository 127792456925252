.sectionContainer {
  width: 80vw;
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  line-height: 1.4em;
}
.AboutUsImg {
  width: 40%;
  max-width: 137px;
  margin: 4% 0;
}

.aboutUsParagraph {
  font-family: "Montserrat";
}

@media (min-width: 768px) {
  .AboutUsImg {
    width: 150px;
    margin: 4%;
  }
  .aboutUsParagraph {
    font-size: 20px;
    line-height: 1.2em;
  }
}

@media (min-width: 1024px) {
  .sectionContainer {
    flex-flow: row;
  }
}
