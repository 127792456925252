.Main {
  margin-top: 60px;
  max-width: 1920px;
  display: flex;
  flex-flow: column;
}
.warning {
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 20px;
}
