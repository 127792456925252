.cookiePreferences {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}

.cookieParagraph {
  text-wrap: wrap;
  margin: 0;
}
.wrapper {
  margin: 24px auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.savePreferencesBtn {
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 0.4rem 1rem;
  cursor: pointer;
  width: 180px;
  font-size: small;
}
