.SectionTitle {
    width: 80%;
    margin: 3vh auto;
    height: 100px;
    background-color: #f7f7f7;
    text-align: center;
    line-height: 100px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    font-family: "Lato";
    font-size: 20px;
}

@media (min-width: 1024px) {
    .SectionTitle {
        font-size: 28px;
    }
}