.toolbar {
  width: 100%;
  height: 60px;
  min-height: 60px;
  display: flex;
  position: fixed;
  right: 0;
  top: 0;
  background-color: white;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 2px 2px 10px #ddd;
  z-index: 100;
  transition: top 0.3s;
}
/* SHOP NOW BUTTON */
.shopNowBtn {
  margin: 0 0;
  padding: 0;
  position: relative;
  margin-right: 20px;
  display: block;
  height: 32px;
  min-width: 130px;
  outline: none;
  color: rgba(0, 0, 0, 0.9);
  background-color: #e55934;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  line-height: 32px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  overflow: hidden;
}

.cart {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%;
  top: 0;
  left: 0;
  color: white;
  background-color: #c53f1a;
  transition: left 0.4s ease-in-out;
  z-index: 1;
}

.cartTxt {
  margin-left: 16px;
}

.storeLink {
  display: block;
  height: 100%;
  width: 100%;
}

.btnTxtAreaTake {
  color: white;
  position: absolute;
  text-align: center;
  left: 30px;
  top: 0;
  text-align: left;
  opacity: 1;
  width: 200px;
  z-index: 0;
}
.navItems {
  display: none;
}

.Logo {
  display: none;
}
.ProductsBar {
  display: none;
}

.Hamburger {
  display: block;
  margin-left: 4%;
  margin-right: auto;
}
.toolbarHidden {
  top: -70px;
}
.languageOptionsContainer {
  display: none;
}

@media (min-width: 1024px) {
  .toolbar {
    z-index: 100;
  }
  .toolbarShown {
    top: 0;
  }
  .toolbarHidden {
    top: -70px;
  }
  .navItems {
    display: block;
    min-width: 400px;
    margin-right: 20px;
  }

  .languageOptionsContainer {
    display: block;
    width: 14vw;
    min-width: 240px;
  }

  .Logo {
    display: block;
    width: 10%;
    padding-left: 1%;
    margin-right: auto;
  }
  .Hamburger {
    display: none;
  }
  .ProductsBar {
    display: block;
    position: fixed;
    top: 60px;
    left: 0;
    background-color: #fff;
    min-height: 136px;
    z-index: 99;
    width: 100%;
    transition: all 0.4s ease;
  }

  .ProductsBarSlideDown {
    top: 60px;
  }
  .ProductsBarSlideUp {
    top: -136px;
  }
  .ProductsBarHidden {
    display: none;
  }

  .shopNowBtn:hover .cart {
    left: 120px;
  }
}
