.SideDrawer {
  display: flex;
  flex-flow: column;
  position: fixed;
  width: 300px;
  max-width: 85%;
  min-height: calc(100vh - 60px);
  left: 0;
  top: 0;
  z-index: 200;
  margin-top: 60px;
  background-color: #fff;
  padding: 24px 0 0 0;
  box-sizing: border-box;
  transform: translateX(-105%);
  transition: transform 0.3s ease-out;
  box-shadow: 3px 3px 4px -1px rgba(0, 0, 0, 0.45);
}

.navSide {
  position: relative;
  flex-grow: 1;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: auto;
}

.langs {
  margin-top: 16px;
}

.Divider {
  margin-top: 5px;
  height: 10px;
  width: 100%;
  border-radius: 100%;
  background-color: white;
  box-shadow: 0 10px 8px -4px tomato;
}

@media (min-width: 1024px) {
  .SideDrawer {
    display: none;
  }
}

.Open {
  transform: translateX(0);
}
.Closed {
  transform: translateX(-107%);
}

.Closer {
  position: absolute;
  top: 2%;
  right: 6%;
}
